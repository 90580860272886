<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="站点名称" prop="name">
                <el-input v-model="query.name" placeholder="请输入站点名称" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="核销日期" >
                <el-date-picker
                    v-model="dateList"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    @change="selectDate"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
                <el-button type="warning" size="mini" v-hasPermi="['ums:site:settle']" @click="showSettleModel" >结算</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="站点名称" align="center" prop="siteName" />
            <el-table-column label="订单号" align="center" prop="orderSn" />
            <el-table-column label="支付金额" align="center" prop="payMoney" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.payMoney).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="佣金" align="center" prop="saleMoney" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.saleMoney).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="分销类型" align="center" prop="saleType" >
                <template #default="scope">
                    {{ scope.row.saleType == 1 ?'一级佣金':'二级佣金' }}
                </template>
            </el-table-column> -->
            <el-table-column label="头像" align="center" prop="headImg">
                <template v-slot="slot">
                    <div v-if="slot.row.headImg == null || slot.row.headImg == ''">未上传头像</div>
                    <img :src="slot.row.headImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="会员昵称" align="center" prop="nickname" />
            <el-table-column label="手机号" align="center" prop="phone" />
            <!-- <el-table-column label="下单时间" align="center" prop="createTime" />
            <el-table-column label="支付时间" align="center" prop="payTime" /> -->
            <el-table-column label="核销状态" align="center" prop="writeOff" >
                <template #default="scope">
                    <el-tag :type="scope.row.writeOff == 1?'success':'info'">{{ scope.row.writeOff == 0 ?'未核销':'已核销' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="核销时间" align="center" prop="offsetTime" />  
            <el-table-column label="结算编号" align="center" prop="settleId" />  
            <el-table-column label="结算状态" align="center" prop="settleState" >
                <template #default="scope">
                    <el-tag :type="scope.row.settleState == 1?'success':'info'">{{ scope.row.settleState == 1 ?'已结算':'未结算' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="结算时间" align="center" prop="settleTime" />  
            
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />

        <!-- 结算弹窗 -->
        <el-dialog title="订单结算" :visible.sync="settleStatus" width="950px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="130px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="站点" prop="siteId">
                            <el-select v-model="form.siteId" filterable placeholder="请选择站点" @change="selectSite">
                                <el-option v-for="item in siteList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="订单数" >
                            {{ form.orderNum || 0 }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="数量" >
                            {{ form.goodNum || 0 }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item >
                    <el-table class="tableList" :data="settleOrderList" style="width: 100%">
                        <el-table-column prop="orderSn" label="订单号" width="180"></el-table-column>
                        <el-table-column prop="itemList">
                            <template #header>
                                <el-row class="order-row-item order-border-none">
                                    <el-col :span="6">产品图</el-col>
                                    <el-col :span="8">产品名称</el-col>
                                    <el-col :span="6">详情/规格</el-col>
                                    <el-col :span="4">数量</el-col>
                                </el-row>
                            </template>
                            <template #default="scope">
                                <div style="flex:1;display:flex;flex-direction: column;">
                                    <div v-for="(order, index) in scope.row.itemList" :key="order.id" style="flex: 1;height:100%">
                                        <el-row class="order-row-item" style="height:100%">
                                            <el-col :span="4">
                                                <img :src="order.skuPic" width="80px" alt="image error">
                                            </el-col>
                                            <el-col :span="8">{{ order.spuName }}</el-col>
                                            <el-col :span="8">{{ order.skuName }}</el-col>
                                            <el-col :span="4">{{ order.skuQuantity + order.sendOutNum }}</el-col>
                                        </el-row>
                                        <el-divider
                                            style="margin: 0 -8px;width:calc(100% + 16px);border-color:var(--el-border-color-light)"
                                            v-if="index < scope.row.itemList.length - 1" />
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button :disabled="settleOrderList.length == 0" type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'Brokerage',
    data() {
        return {
            // 列表
            list: [],
            // 类型
            dictType: [],
            // 总条数
            total: 0,
            // 遮罩层
            loading: false,
            // 查询
            query: {
                current: 1,
                size: 10,
                type: 2,
                name: '',
                startDate: '',
                endDate: '',
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 选择日期
            dateList: [],
            // 结算弹窗
            settleStatus: false,
            // 结算提交数据
            form: {
                siteId: null,
                orderNum: 0,
                goodNum: 0,
                orderList: []
            },
            // 站点列表
            siteList: [],
            // 结算订单列表
            settleOrderList: [],
            // 表单校验
            rules: {
                siteId: [{ required: true, message: "请选择站点", trigger: "blur" }],
            },
        }
    },
    created() {
        this.getList()
        this.getSiteList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.earningsInfoList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 获取站点数据
        getSiteList() {
            api.getSiteList().then(res => {
                this.siteList = res
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        // 重置
        reset() {
            this.query = {
                current: 1,
                size: 10,
                type: 2,
                name: '',
                startDate: '',
                endDate: '',
            }
            this.settleOrderList = []
            this.settleStatus = false
            this.dateList = []
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 选择日期 */
        selectDate(e) {
            console.log(e);
            if (e != null && e.length > 0) {
                this.query.current = 1
                this.query.startDate = e[0]
                this.query.endDate = e[1]
                this.getList()
            }else {
                this.query.current = 1
                this.query.startDate = ''
                this.query.endDate = ''
                this.getList()
            }
        },
        /** 显示结算弹窗 */
        showSettleModel() {
            this.form = {
                siteId: null,
                orderNum: 0,
                goodNum: 0,
                orderList: []
            }
            this.settleOrderList = []
            this.settleStatus = true
        },
        /** 确定 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    console.log(this.form);
                    this.form.orderList = this.settleOrderList
                    api.addSettle(this.form).then(res => {
                        this.$message.success('结算成功')
                        this.cancel()
                    })
                }
            })
        },
        // 取消按钮
        cancel() {
            this.form = {
                siteId: null,
                orderNum: 0,
                goodNum: 0,
                orderList: []
            }
            this.settleOrderList = []
            this.settleStatus = false;
            this.getList()
        },
        // 选择站点
        selectSite(e) {
            console.log(e);
            api.siteSettleOrders({siteId: e}).then(res => {
                this.settleOrderList = res.orderList
                this.form.orderNum = res.orderNum
                this.form.goodNum = res.goodNum
            })
        },
    }
}

</script>


<style>
.tableList {
    border: 1px solid var(--el-table-border-color);
    border-bottom: none;

    :deep(thead .cell) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    :deep(thead th:last-child .cell) {
        justify-content: center;
    }

    :deep(thead .el-icon) {
        margin-top: -2px;
        cursor: pointer;
    }

    .primary {
        cursor: pointer;
        color: var(--el-color-primary);
    }

    :deep(.el-button) {
        margin-left: 0;
        margin-right: 12px;
    }

    :deep(.el-table__body td.el-table__cell) {
        border-right: 1px solid var(--el-border-color-light);
        padding: 0;

        &:last-child {
            border-right: none;
        }
    }

    .order-row-item {
        width: 100%;

        :deep(.el-col) {
            border-right: 1px solid var(--el-border-color-light);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-child {
                border-right: none;
            }

            &:first-child,
            &:nth-child(2) {
                text-align: left;
                justify-content: flex-start;
                padding-left: 8px;
            }
        }
    }

    .order-border-none {
        :deep(.el-col) {
            border: none;
        }
    }
}
</style>